// Import package from Soil plugin example.
import $ from "@modules/jquery";

function thintopbarResize() {
  const query = Foundation.MediaQuery.get(Foundation.MediaQuery.current);
  if (typeof query !== "string") {
    return;
  }

  const queryWidth = Math.min(query.match(
    /\d*(?:min-width|max-width):\s*(\d+\s?)(px)/
  )[1], 1280);
  const width = ($(window).width() - queryWidth) / 2;
  $(".ms_thintopbar").css("padding-right", width);
}

addEventListener("resize", (_) => {
  thintopbarResize();
});

$(function() {
  thintopbarResize();

  $(".is-dropdown-submenu-parent").each(function () {
    const button = $(this);
    const submenu = button.find("> ul");
    const buttonCenter = button.width() / 2;
    const submenuCenter = submenu.width() / 2;
    submenu.css("left", buttonCenter - submenuCenter + "px");
  });
});


$(document).ready(function() {

  const mediaQuery = setInterval(() => {
      if (Foundation.MediaQuery.queries.length === 0) {
        return;
      }

      if (Foundation.MediaQuery.current !== "") {
        clearInterval(mediaQuery);
      }

      if (Foundation.MediaQuery.upTo("sm") ) {
        const columns = document.querySelectorAll(".wp-block-column");
        columns.forEach((column) => {
          column.style.order = "1";
          if (column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0) {
            column.style.order = "2";
          }
        });
      }
    });

  jQuery(window).on("changed.zf.mediaquery", function (event, newSize, oldSize) {
      if (Foundation.MediaQuery.queries.length === 0) {
          return;
      }
      if (Foundation.MediaQuery.upTo("sm")) {
          const columns = document.querySelectorAll(".wp-block-column");
          columns.forEach((column) => {
          column.style.order = "1";
          if (
              column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0
          ) {
              column.style.order = "2";
          }
          });
      }
      else {
          const columns = document.querySelectorAll(".wp-block-column");
          columns.forEach((column) => {
              column.style.order = "1";
          });
      }
  });
});
